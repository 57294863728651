import { DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import Highcharts, { GradientColorObject, Options, Point, SeriesBarOptions, SeriesOptionsType, SeriesSplineOptions, TooltipOptions, XAxisOptions } from 'highcharts';
import { chunk, cloneDeep, findLastIndex, first, last, maxBy, merge } from 'lodash-es';
import moment, { Moment } from 'moment';
import { AnalysisSettingsService } from '../../analysis/shared/analysis-settings.service';
import { IAttributionMetadataMetricDto } from '../../api-model/attribution-metadata-metric-dto';
import { Cadence } from '../../api-model/enums/cadence';
import { ITrendDateWithTargetDto } from '../../api-model/trend-date-with-target-dto';
import { ITrendDateWithTargetRangeDto } from '../../api-model/trend-date-with-target-range-dto';
import { defaultChartOptions } from '../../default-chart-options';
import { CadenceDatePipe } from '../pipes/cadence-date.pipe';
import { MetricValuePipe } from '../pipes/metric-value.pipe';
import { WeekDateRangePipe } from '../pipes/week-date-range.pipe';
import { MonthDateRangePipe } from '../pipes/month-date-range.pipe';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: 'root'
})
export class ChartHelperService {

  public constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly percentPipe: PercentPipe,
    private readonly metricValuePipe: MetricValuePipe,
    private readonly weekDateRangePipe: WeekDateRangePipe,
    private readonly monthDateRangePipe: MonthDateRangePipe,
    private readonly cadenceDate: CadenceDatePipe,
    private readonly theme: ThemeService,
    private readonly analysisSettings: AnalysisSettingsService
  ) { }

  public get annotationsFontFamily() { return `'Inconsolata', sans-serif`; }

  public get bgColour() { return this.theme.dark ? '#222' : '#f8f8f8'; }

  public get plotBgColour() { return this.theme.dark ? '#181818' : '#ebebeb'; }

  public get tiledPlotBgColour() { return this.theme.dark ? '#181818' : '#ddd'; }

  public get lineColour() { return this.theme.dark ? '#aaa' : '#fff'; }

  public get xAxisGridLineColour() { return this.theme.dark ? '#333' : '#ccc'; }

  public get yAxisGridLineColour() { return this.theme.dark ? '#666' : '#bbb'; }

  public get minorGridLineColour() { return this.theme.dark ? '#333' : '#ddd'; }

  public get axisLineColour() { return this.theme.dark ? '#999' : '#ccd6eb'; }

  public get crosshairColour() { return this.theme.dark ? '#888' : '#333'; }

  public get pieBorderColour() { return this.theme.dark ? 'rgba(255, 255, 255, 0.4)' : '#fff'; }

  public get legendItemColour() { return this.theme.dark ? '#aaa' : '#222'; }

  public get legendItemHoverColour() { return this.theme.dark ? '#fff' : '#000'; }

  public get axisLabelColour() { return this.theme.dark ? '#999' : '#666'; }

  public get axisTitleColour() { return this.theme.dark ? '#999' : '#666'; }

  public get neutralColour() { return this.theme.dark ? '#ccc' : '#222'; }

  public get goodColour() { return this.theme.dark ? '#60b367' : '#007a0b'; }

  public get badColour() { return this.theme.dark ? '#db4d5a' : '#cc182a'; }

  public get plotLineLabelColour() { return this.theme.dark ? '#ccc' : '#333'; }

  public get tooltipBgColour() { return this.theme.dark ? 'black' : 'white'; }

  public get plotBandBgColor() { return this.theme.dark ? '#7022a316' : '#7022a322'; }

  public get baseTooltip() {
    return {
      useHTML: true,
      shared: true,
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      style: {
        padding: '0'
      },
      followPointer: true
    } as TooltipOptions;
  }

  public donutChart(tooltipValueFormatter?: (x: number) => string): Highcharts.Options {
    // tslint:disable: object-literal-shorthand
    // tslint:disable-next-line: no-this-assignment
    const self = this;
    return merge(cloneDeep(defaultChartOptions), {
      chart: {
        type: 'pie',
        backgroundColor: this.bgColour,
        height: 300,
        width: 300,
        spacing: [10, 10, 10, 10]
      },
      yAxis: {
        gridLineWidth: 1,
        gridLineColor: this.xAxisGridLineColour
      },
      xAxis: {
        title: { text: '' }
      },

      tooltip: merge(cloneDeep(this.baseTooltip), {
        formatter() {
          if (!this.point) { return ''; }
          const value = (tooltipValueFormatter || (x => self.decimalPipe.transform(x, '1.0-0')))(this.point.y);
          if (!value) { return ''; }
          const icon = (this.point as any).iconUrl ? `<img src="${(this.point as any).iconUrl}" alt="" />` : '';
          const bullet = !this.point.color.toString().includes('url') ? `<span class="bullet" style="background: ${this.point.color};"></span>` : '';
          const percentage = `<td class="percentage">${self.percentPipe.transform(this.percentage / 100, '1.0-1')}</td>`;
          return `<div class="chart-tooltip"><h3>${icon}${this.point.name}</h3><table><tbody>` +
            `<tr><th><div>${bullet}<span class="value">${value}</span></div></th>${percentage}</tr>` +
            '</tbody></table></div>';
        },
        outside: true,
        style: { width: 400 }
      }),
      plotOptions: {
        pie: {
          dataLabels: { enabled: false },
          innerSize: 100,
          borderColor: this.pieBorderColour,
          borderWidth: 1
        }
      },
      series: []
    } as Options);
    // tslint:restore: object-literal-shorthand
  }

  public timeseriesAreaChart(): Highcharts.Options {
    return merge(cloneDeep(defaultChartOptions), {
      chart: {
        type: 'areaspline',
        backgroundColor: this.bgColour,
        plotBackgroundColor: this.plotBgColour,
        height: 350,
        spacing: [20, 20, 15, 20]
      },
      xAxis: {
        categories: [],
        title: {
          style: { color: this.axisTitleColour }
        },
        labels: {
          enabled: true,
          style: { color: this.axisLabelColour }
        },
        crosshair: { color: this.crosshairColour },
        lineColor: this.axisLineColour,
        gridLineColor: this.xAxisGridLineColour,
        gridLineWidth: 0,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Volume',
          style: { color: this.axisTitleColour }
        },
        gridLineColor: this.yAxisGridLineColour,
        gridLineWidth: 0.5,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0,
        lineColor: this.axisLineColour,
        labels: {
          style: { color: this.axisLabelColour }
        }
      },
      legend: {
        enabled: true,
        itemMarginBottom: 5,
        itemStyle: {
          color: this.legendItemColour,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: this.legendItemHoverColour
        }
      },
      tooltip: this.tooltip(function () { return Highcharts.numberFormat(this.y, 0); }),
      plotOptions: {
        areaspline: {
          stacking: 'normal',
          lineColor: this.lineColour,
          lineWidth: 0.5,
          marker: { enabled: false },
          fillOpacity: 0.85
        },
        spline: {
          marker: { enabled: false }
        }
      },
      series: []
    } as Highcharts.Options);
  }

  public dateLabelledSeriesData(data: any[], dateLabels: string[]): any[] {
    return data.map((x, i) => {
      // noinspection JSSuspiciousNameCombination
      return { name: dateLabels[i], y: x } as Highcharts.DataOptions;
    });
  }

  public tooltip(valueFormatter?: () => string, excludePercentage = false): Highcharts.TooltipOptions {
    // tslint:disable-next-line: no-this-assignment
    const self = this;
    // noinspection JSUnusedGlobalSymbols
    return merge(cloneDeep(this.baseTooltip), {
      headerFormat: `<div class="chart-tooltip"><h3>{point.key}</h3>${valueFormatter ? '<table><tbody>' : ''}`,
      pointFormatter() {
        const value = valueFormatter?.call(this);
        const seriesOptions = this.series.options;
        if (!value
          || (typeof seriesOptions.showInTooltip === 'boolean' && !seriesOptions.showInTooltip)
          || (typeof seriesOptions.showInTooltip === 'function' && !seriesOptions.showInTooltip(this))
        ) { return ''; }
        const bullet = !this.series.color.toString().includes('url') ? `<span class="bullet" style="background: ${this.series.color};"></span>` : '';
        const percentage = !excludePercentage && this.percentage ? `<td class="percentage">${self.percentPipe.transform(this.percentage / 100, '1.0-1')}</td>` : '';
        return `  <tr><th><div>${bullet}<span class="value">${this.series.chart.options.series.find((x: SeriesOptionsType) => x.name === this.series.name)?.tooltipName || this.series.name}</span></div></th><td class="values">${value}</td>${percentage}</tr>`;
      },
      footerFormat: `${valueFormatter ? '</tbody></table>' : ''}</div>`
    });
  }

  // noinspection JSUnusedGlobalSymbols
  public barChartTooltipPositioner(anchorPointSelector?: (points: Point[]) => Point) {
    return function positioner(width: number, height: number) {
      const groupPoints = this.chart.series.map((s: SeriesBarOptions) => s.data[this.chart.hoverPoint.index]) as Point[];
      const maxPoint = anchorPointSelector ? anchorPointSelector(groupPoints) : maxBy(groupPoints, x => x.y);
      const maxPointAnchor = this.getAnchor(maxPoint);
      return this.getPosition(width, height, {
        plotX: maxPointAnchor[0],
        plotY: maxPointAnchor[1],
        negative: (maxPoint as any).negative,
        ttBelow: (maxPoint as any).ttBelow,
        h: maxPointAnchor[2]
      });
    };
  }

  public addCadenceWeekendPlotBands(options: Options, dates: Moment[]) {
    if (dates?.length < 3) { return; }
    const xAxis = (Array.isArray(options.xAxis) ? (options.xAxis as XAxisOptions[])[0] : options.xAxis as XAxisOptions);

    let bands: {
      from: number;
      to: number;
    }[] = [];
    if (this.analysisSettings.cadence === Cadence.Daily) {
      const weekendIndices = (dates || []).map((x, i) => ({ isWeekend: x.isoWeekday() >= 6, index: i })).filter(x => x.isWeekend).map(x => x.index);
      if (first(dates)?.isoWeekday() === 7) { weekendIndices.unshift(0); }
      bands = chunk(weekendIndices, 2).map(x => ({ from: first(x), to: last(x) + 1 }));
    } else if (this.analysisSettings.cadence === Cadence.Weekly) {
      bands = dates.map((_, i) => ({ from: i + 1 - (2 / 7), to: i + 1 }));
    }

    xAxis.plotBands = (xAxis.plotBands || []).concat(bands.map(x => ({
      from: x.from,
      to: x.to,
      color: this.theme.dark ? '#ffffff09' : '#00000009'
    })));
  }

  public addCadencePlotBands(options: Options, dates: Moment[]) {
    if (dates?.length < 3) { return; }
    const xAxis = (Array.isArray(options.xAxis) ? (options.xAxis as XAxisOptions[])[0] : options.xAxis as XAxisOptions);

    if (!xAxis.plotBands) { xAxis.plotBands = []; }
    if (!xAxis.plotLines) { xAxis.plotLines = []; }

    if (this.analysisSettings.cadence === Cadence.Weekly || this.analysisSettings.cadence === Cadence.Monthly) {
      dates.slice(0, -1).forEach((date, i) => {
        let showLabel = dates.length <= 8 || i % Math.floor(dates.length / 8) === 0;
        const rangeLabel = this.analysisSettings.cadence === Cadence.Weekly ? this.weekDateRangePipe.transform(date) : this.monthDateRangePipe.transform(date)
        xAxis.plotBands.push(this.getCadencePlotBand(i, i + 1, showLabel ? rangeLabel : '', options.chart.height as number));
        xAxis.plotLines.push(this.generateCadencePlotLine(i + 1));
      });
    } else {
      let start = 0;
      let end = 8 - first(dates).isoWeekday();
      let last = dates.length;
      while (start < last) {
        let showLabel = end - start >= 4;
        xAxis.plotBands.push(this.getCadencePlotBand(start, end, showLabel ? this.weekDateRangePipe.transform(dates[start]) : '', options.chart.height as number));
        if (end < last) { xAxis.plotLines.push(this.generateCadencePlotLine(end)); }
        start = end;
        end = Math.min(start + 7, last);
      }
    }
  }

  public gaugeChart(config: {
    actual: number;
    targetMin: number;
    targetMax: number;
    axisLabelFormatter: (x: number) => string;
    underTargetIsGood?: boolean;
  }) {

    let worseThanTargetMin: number;
    let worseThanTargetMax: number;

    const padding = (Math.max(config.targetMax, config.actual) - Math.min(config.targetMin, config.actual)) * 0.1;
    if (config.actual > config.targetMax) {
      worseThanTargetMin = config.targetMax;
      worseThanTargetMax = config.actual + padding;
    } else if (config.actual < config.targetMin) {
      worseThanTargetMin = config.actual - padding;
      worseThanTargetMax = config.targetMin;
    } else if (config.underTargetIsGood) {
      worseThanTargetMin = config.targetMax;
      worseThanTargetMax = config.targetMax + padding;
    } else {
      worseThanTargetMin = config.targetMin - padding;
      worseThanTargetMax = config.targetMin;
    }

    return merge(cloneDeep(defaultChartOptions), {
      chart: {
        backgroundColor: 'transparent',
        type: 'gauge',
        height: 150,
        width: 300,
        spacing: [0, 0, 0, 0]
      },
      title: { text: '' },
      pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
      },
      xAxis: {
        crosshair: false
      },
      yAxis: {
        min: Math.min(config.targetMin, worseThanTargetMin),
        max: Math.max(config.targetMax, worseThanTargetMax),
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: '#ffffff66',
        lineColor: '#ffffff66',
        lineWidth: 0,
        tickLength: 30,
        tickWidth: 1,
        minorTickInterval: null,
        labels: {
          distance: 18,
          style: {
            color: this.theme.dark ? '#ffffff66' : '#00000066',
            fontFamily: this.annotationsFontFamily,
            fontSize: '12px'
          },
          formatter: function () { return config.axisLabelFormatter(this.value); }
        } as any,
        plotBands: [{
          from: worseThanTargetMin,
          to: worseThanTargetMax,
          color: this.theme.dark ? '#265b89' : '#5892c4',
          thickness: 30
        }, {
          from: config.targetMin,
          to: config.targetMax,
          color: this.goodColour,
          thickness: 30
        }]
      },
      tooltip: { enabled: false },
      series: [{
        name: '30 Day Average',
        data: [config.actual],
        dataLabels: { enabled: false },
        dial: {
          radius: '80%',
          backgroundColor: this.theme.dark ? '#fff' : '#333',
          baseWidth: 12,
          baseLength: '0%',
          rearLength: '0%'
        },
        pivot: {
          backgroundColor: this.theme.dark ? '#fff' : '#333',
          radius: 8
        }

      }]
    } as Options);
  }

  public replaceSinglePointLineWithColumn(options: Highcharts.Options) {
    if (!options) { return; }
    for (const series of options.series) {
      if ((series as SeriesSplineOptions).data?.length === 1) {
        if ((options.chart.type === 'areasplinerange' && !series.type) || series.type === 'areasplinerange') {
          series.type = 'columnrange';
        } else {
          series.type = 'column';
        }

        if (Array.isArray(options.xAxis)) {
          (options.xAxis as XAxisOptions[]).forEach(x => x.crosshair = false);
        } else if (options.xAxis) {
          options.xAxis.crosshair = false;
        }
      }
    }
  }

  public miniTrendLine(config: {
    metric: IAttributionMetadataMetricDto;
    data: {
      value: number;
      date: Date | Moment;
    }[];
  }) {
    // tslint:disable-next-line: no-this-assignment
    const self = this;

    // Don't draw trend lines with less than four points or lines that are all zeroes
    if (!config.data.some(x => x.value) || config.data.length < 2) { return null; }

    const chart = merge(cloneDeep(defaultChartOptions), {
      chart: {
        backgroundColor: 'transparent',
        type: 'spline',
        height: 40,
        width: 80,
        spacing: [5, 5, 5, 5],
        animation: false
      },
      title: { text: '' },
      xAxis: {
        title: { text: '' },
        labels: { enabled: false },
        crosshair: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        visible: false,
        categories: config.data.map(x => this.cadenceDate.transform(x.date))
      },
      yAxis: {
        title: { text: '' },
        labels: { enabled: false },
        crosshair: false,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        visible: false
      },
      legend: { enabled: false },
      tooltip: merge(cloneDeep(this.baseTooltip), {
        headerFormat: `<div class="chart-tooltip"><table class="tight-margin"><tbody>`,
        pointFormatter() {
          const value = (self.metricValuePipe.transform(this.y || 0, config.metric, { expand: true }));
          if (!value) { return ''; }
          return `  <tr><td class="label">${this.category}</td><td class="value">${value}</td></tr>`;
        },
        footerFormat: `</tbody></table>`,
        outside: true,
        style: { width: 400 }
      } as TooltipOptions),
      series: [{
        name: config.metric.shortDisplayName || config.metric.displayName,
        data: config.data.map(x => x.value),
        color: this.neutralColour
      }],
      plotOptions: {
        spline: { animation: false, marker: { radius: 3 }, states: { hover: { halo: { size: 0, enabled: false } } } }
      }
    } as Options);

    const series = chart.series[0] as SeriesSplineOptions;

    if (!config.metric.neutral) {
      if (first(series.data) < last(series.data)) {
        series.color = config.metric.negativeIsGood ? this.badColour : this.goodColour;
      } else if (first(series.data) > last(series.data)) {
        series.color = config.metric.negativeIsGood ? this.goodColour : this.badColour;
      }
    }

    return chart;
  }

  public inlineDonutChart(tooltipValueFormatter?: (x: number) => string) {
    return merge(this.donutChart(tooltipValueFormatter), {
      chart: {
        backgroundColor: 'transparent',
        height: 150,
        width: 150,
        spacing: [0, 0, 0, 0]
      },
      title: {
        useHTML: true,
        text: '',
        align: 'center',
        verticalAlign: 'middle'
      },
      plotOptions: {
        pie: {
          innerSize: '70%',
          borderColor: 'transparent',
          borderWidth: 0
        }
      }
    } as Options);
  }

  public splineChart() {
    return merge(this.spendOptimisationChart(), {
      chart: {
        type: 'spline'
      }
    }) as Options;
  }

  public barChart() {
    return merge(this.spendOptimisationChart(), {
      chart: { type: 'bar' }
    }) as Options;
  }

  public spendOptimisationChart() {
    return merge(cloneDeep(defaultChartOptions), {
      chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: this.plotBgColour,
        spacing: [20, 20, 15, 20],
        height: 350
      },
      xAxis: {
        categories: [],
        title: {
          style: { color: this.axisTitleColour }
        },
        labels: {
          enabled: true,
          style: { color: this.axisLabelColour }
        },
        crosshair: { color: this.crosshairColour },
        lineColor: this.axisLineColour,
        gridLineColor: this.xAxisGridLineColour,
        gridLineWidth: 0,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0
      },
      yAxis: {
        min: 0,
        title: {
          style: { color: this.axisTitleColour }
        },
        gridLineColor: this.yAxisGridLineColour,
        gridLineWidth: 0.5,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0,
        lineColor: this.axisLineColour,
        labels: {
          enabled: true,
          style: { color: this.axisLabelColour }
        }
      },
      legend: {
        enabled: true,
        itemMarginBottom: 5,
        itemStyle: {
          color: this.legendItemColour,
          fontWeight: 'normal'
        },
        itemHoverStyle: { color: this.legendItemHoverColour }
      },
      plotOptions: {
        bar: { borderWidth: 0 },
        column: { borderWidth: 0 },
        spline: { marker: { enabled: false } },
        areaspline: {
          stacking: 'normal',
          lineWidth: 0.5,
          marker: { enabled: false },
          fillOpacity: 0.85
        }
      }
    } as Options);
  }

  public recentTrendsTimeseriesChart(config: {
    name: string;
    data: (ITrendDateWithTargetDto | ITrendDateWithTargetRangeDto)[];
    metricConfig: IAttributionMetadataMetricDto;
    tooltipValueFormatter: () => string;
    tooltipRangeValueFormatter: () => string;
  }) {
    const dates = config.data.map(x => moment.utc(x.date));
    const todayIndex = findLastIndex(dates, x => x.isSameOrBefore(Date.now())) || 0;
    const options = merge(this.splineChart(), {
      chart: {
        height: 350,
        plotBackgroundColor: this.tiledPlotBgColour
      },
      yAxis: [
        {
          title: { text: '' },
          labels: { enabled: false },
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          visible: false,
          minPadding: 0.2,
          maxPadding: 0.2
        }
      ],
      xAxis: {
        lineWidth: 0,
        categories: dates.map(x => this.cadenceDate.transform(x, false)),
        labels: { enabled: false }
      },
      tooltip: this.tooltip(function () {
        return this.low && this.high ? config.tooltipRangeValueFormatter.call(this) : config.tooltipValueFormatter.call(this);
      }),
      series: [
        {
          name: `${config.name}`,
          color: this.theme.dark ? '#38afff' : '#2984c2',
          data: config.data.slice(0, todayIndex + 1).map(x => x.value),
          zIndex: 100
        },
        {
          name: `${config.name} (Predicted)`,
          color: this.theme.dark ? '#38afff' : '#2984c2',
          data: config.data.slice(0, todayIndex).map(_ => null).concat(config.data.slice(todayIndex).map(x => x.value)),
          dashStyle: 'dash',
          zIndex: 100,
          showInLegend: false,
          showInTooltip: (p: {
            index: number;
          }) => p.index !== todayIndex
        },
        config.metricConfig.ranged || config.metricConfig.name.startsWith('organic') ? null : {
          name: 'Target',
          color: this.theme.dark ? '#63c96c' : '#007a0b',
          data: config.data.slice(0, todayIndex + 1).map((x: ITrendDateWithTargetDto) => x.target),
          zIndex: 100
        },
        config.metricConfig.ranged || config.metricConfig.name.startsWith('organic') ? null : {
          name: 'Target',
          color: this.theme.dark ? '#63c96c' : '#007a0b',
          data: config.data.slice(0, todayIndex).map(_ => null).concat(config.data.slice(todayIndex).map((x: ITrendDateWithTargetDto) => x.target)),
          dashStyle: 'dash',
          zIndex: 100,
          showInLegend: false,
          showInTooltip: (p: {
            index: number;
          }) => p.index !== todayIndex
        },
        !config.metricConfig.ranged || config.metricConfig.name.startsWith('organic') ? null : {
          name: 'Target Range',
          type: 'areasplinerange',
          color: this.theme.dark ? '#63c96c' : '#007a0b',
          data: config.data.slice(0, todayIndex + 1).map((x: ITrendDateWithTargetRangeDto) => [x.targetMin, x.targetMax]),
          zIndex: 100,
          fillOpacity: 0.25,
          opacity: 0.7
        },
        !config.metricConfig.ranged || config.metricConfig.name.startsWith('organic') ? null : {
          name: 'Target Range',
          type: 'areasplinerange',
          color: this.theme.dark ? '#63c96c' : '#007a0b',
          data: config.data.slice(0, todayIndex).map(_ => null).concat(config.data.slice(todayIndex).map((x: ITrendDateWithTargetRangeDto) => [x.targetMin, x.targetMax])),
          dashStyle: 'dash',
          zIndex: 100,
          fillOpacity: 0.25,
          opacity: 0.5,
          showInLegend: false,
          showInTooltip: (p: {
            index: number;
          }) => p.index !== todayIndex
        }
      ].filter(x => x),
      plotOptions: {
        areaspline: {
          stacking: null
        },
        column: {
          groupPadding: 0.4
        }
      }
    } as Options);

    this.plotTodayMarker(options, dates, this.analysisSettings.cadence);
    this.addCadencePlotBands(options, dates);
    this.addCadenceWeekendPlotBands(options, dates);
    this.replaceSinglePointLineWithColumn(options);

    return options;
  }

  public plotTodayMarker(options: Options, dates: Moment[], cadence: Cadence) {
    let showTodayMarker = false;
    switch (cadence) {
      case Cadence.Weekly: {
        if (!moment.utc().startOf('isoWeek').diff(this.analysisSettings.dateRange.end, 'day')) showTodayMarker = true
        break;
      }
      case Cadence.Monthly: {
        if (!moment.utc().startOf('month').diff(this.analysisSettings.dateRange.end, 'day')) showTodayMarker = true
        break;
      }
      default: {
        if (!moment.utc().diff(this.analysisSettings.dateRange.end, 'day')) showTodayMarker = true
        break;
      }
    }

    if (showTodayMarker) {
      this.addTodayMarker(options, dates);
      this.addTodayBand(options, dates);
    }
  }

  private getXValueJustBeforeTodayInDatesArray(dates: Moment[]) {
    return findLastIndex(dates, x => x.isSameOrBefore(Date.now())) || 0;
  }


  public addTodayMarker(options: Options, dates?: Moment[]) {
    (options.xAxis as XAxisOptions).plotLines = ((options.xAxis as XAxisOptions).plotLines || []).concat([{
      value: this.getPlotLinePosition(dates),
      width: 0.5,
      zIndex: 5000,
      color: 'transparent',
      label: {
        text: '▶',
        style: {
          color: this.plotLineLabelColour,
          fontFamily: 'Arial',
          fontSize: '14px'
        },
        x: -6,
        y: 12
      }
    }]);
  }

  public addTodayBand(options: Options, dates: Moment[]) {
    const DIVIDING_FACTOR = 400;
    const widthOfBand = dates.length / DIVIDING_FACTOR;
    const todayXValuePosition = this.getPlotLinePosition(dates);
    
    (options.xAxis as XAxisOptions).plotBands = ((options.xAxis as XAxisOptions).plotBands || []).concat([{
      from: todayXValuePosition - (widthOfBand / 2),
      to: todayXValuePosition + (widthOfBand / 2),
      zIndex: 5000,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, 'transparent'],
          [0.05, 'transparent'],
          [0.05, this.theme.dark ? '#ffffff99' : '#00000099'],
          [1, this.theme.dark ? '#ffffff99' : '#00000099']
        ],
        zIndex: 9
      } as GradientColorObject,
      label: {
        text: 'Today',
        style: {
          color: this.plotLineLabelColour,
          fontSize: '11px',
          fontFamily: this.annotationsFontFamily
        },
        rotation: 90,
        x: 6,
        y: 26,
        align: 'left'
      }
    }, {
      from: this.getXValueJustBeforeTodayInDatesArray(dates),
      to: dates.length - 1,
      color: this.theme.dark ? '#7022a316' : '#7022a322'
    }]);
  }

  private getPlotLinePosition(dates: Moment[]) {
    const today = moment().utc().startOf('day').valueOf();
    const timestamps = dates?.map(date => date.utc().startOf('day').valueOf());
    // Find the position for today's date by comparing timestamps
    for (let i = 0; i < timestamps.length - 1; i++) {
      if (today === timestamps[i]) return i;
      if (today > timestamps[i] && today < timestamps[i + 1]) {
        const position = i + (today - timestamps[i]) / (timestamps[i + 1] - timestamps[i]);
        return position;
      }
    }
    return -1; /* Do not show the today line */
  }

  private generateCadencePlotLine(i: number) {
    return {
      value: i,
      width: 0.5,
      zIndex: 5000,
      color: this.theme.dark ? '#ffffff44' : '#00000044'
    };
  }

  private getCadencePlotBand(start: number, end: number, label: string, chartHeight: number) {
    return {
      from: start,
      to: end,
      color: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, this.theme.dark ? '#ffffff11' : '#ffffff44'],
          [(350 / (chartHeight || 350)) * 0.05, this.theme.dark ? '#ffffff11' : '#ffffff44'],
          [(350 / (chartHeight || 350)) * 0.05, 'transparent'],
          [1, 'transparent']
        ],
        zIndex: 9
      } as GradientColorObject,
      label: {
        text: label,
        style: {
          color: this.theme.dark ? '#ffffff66' : '#00000066',
          fontSize: '11px',
          fontFamily: this.annotationsFontFamily,
          align: 'left'
        },
        y: 10
      }
    };
  }

  public timeseriesLineChart(): Highcharts.Options {
    return merge(cloneDeep(defaultChartOptions), {
      chart: {
        type: 'line',
        backgroundColor: this.bgColour,
        plotBackgroundColor: this.plotBgColour,
        spacing: [30, 20, 15, 10],
      },
      xAxis: {
        categories: [],
        title: {
          style: { color: this.axisTitleColour }
        },
        labels: {
          enabled: true,
          style: { color: this.axisLabelColour }
        },
        crosshair: { color: this.crosshairColour },
        lineColor: this.axisLineColour,
        gridLineColor: this.xAxisGridLineColour,
        gridLineWidth: 0,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          labels: {
            format: '{value}'
          },
          style: { color: this.axisTitleColour }
        },
        gridLineColor: this.yAxisGridLineColour,
        gridLineWidth: 0.5,
        minorGridLineColor: this.minorGridLineColour,
        minorGridLineWidth: 0,
        lineColor: this.axisLineColour,
        labels: {
          style: { color: this.axisLabelColour }
        }
      },
      legend: {
        enabled: true,
        itemMarginBottom: 5,
        itemStyle: {
          color: this.legendItemColour,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: this.legendItemHoverColour
        }
      },
      tooltip: this.tooltip(function () { return Highcharts.numberFormat(this.y, 0); }),
      plotOptions: {
        spline: {
          lineColor: this.lineColour,
          lineWidth: 0.5,
          marker: { enabled: false },
          fillOpacity: 0.85
        },
      },
      series: []
    } as Highcharts.Options);
  }
}
