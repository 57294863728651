<app-page-top-filters>
  <div class="filters">
    <div *ngIf="showRegion" class="filter-cell">
      <label>Region:</label>
      <ng-select [(ngModel)]="regionId" [clearable]="true" [items]="filteredRegions" [searchable]="false" appendTo="body" bindLabel="displayName" bindValue="id"></ng-select>
    </div>
    <div *ngIf="showNetwork" class="filter-cell">
      <label>Network:</label>
      <ng-select [(ngModel)]="networkId" [clearable]="true" [items]="networks" [searchable]="true" appendTo="body" bindLabel="displayName" bindValue="id"></ng-select>
    </div>
    <div *ngIf="showCadence" class="filter-cell">
      <label>Cadence:</label>
      <ng-select [(ngModel)]="cadence" [clearable]="false" [items]="cadences" [searchable]="false" appendTo="body" bindLabel="label" bindValue="value"></ng-select>
    </div>
    <div *ngIf="showDateRange" class="filter-cell">
      <label>Date range:</label>
      <app-button-date-range [(namedRanges)]="namedRanges" [(dateRange)]="dateRange" [(maxSpan)]="maxSpan" (dateRangePresetName)="dateRangeChanged($event)"></app-button-date-range>
    </div>
    <!-- Removed the Smoothing Option -->
    <!-- <div *ngIf="showSmoothing" class="filter-cell">
      <label container="body" delay="500"
              tooltip="If enabled, all values will be calculated using an exponential moving average based on the specified number of {{CadencePeriodPluralDesc[this.cadence]}}, trading accuracy for readability by making it easier to see trends in the data.">Smoothing:</label>
      <ng-select [(ngModel)]="smoothingLevel" [clearable]="false" [items]="smoothingLevels" [searchable]="false" appendTo="body" bindLabel="label" bindValue="value"></ng-select>
    </div> -->
    <ng-content select=".filter-cell"></ng-content>
  </div>
</app-page-top-filters>
